import { useSelector } from 'react-redux';
import { base_url } from './constants';
// const axios = require('axios');
import axios from 'axios';
// const Id = localStorage.getItem('UserId');

const GetAuthToken = () => {
  // const userAuthToken = useSelector(state => state.userDetails.token);
  return localStorage.getItem('UserAuthToken');
  // return userAuthToken;
};

const AgencyLoginHeader = axios.create({
  baseURL: base_url,
  responseType: 'json'
});

// Add an interceptor to update the Authorization header before each request
AgencyLoginHeader.interceptors.request.use(
  async config => {
    const authToken = GetAuthToken();
    if (authToken) {
      config.headers.Authorization = `Bearer ${authToken}`;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

export const AagencyLogin = async (username, pass) => {
  return await axios.post(`${base_url}Agency/SignIn`, {
    loginId: username,
    password: pass
  });
};

export const AagencyLogout = async () => {
  return await axios.post(`${base_url}Agency/SignOut`);
};

export const AagencyForgot = async email => {
  return await axios.post(`${base_url}Agency/Forgot`, {
    loginId: email
  });
};

export const GetAagencyUserDetails = async loginId => {
  try {
    const response = await AgencyLoginHeader.get(
      `/Agency/GetProfile?loginId=${loginId}`
    );
    return response;
  } catch (error) {
    // Handle any errors here
    throw error;
  }
};

export const UpdateAgencyProfile = async UpdatedProfileObj => {
  try {
    const response = await AgencyLoginHeader.post(
      `${base_url}Agency/UpdateProfile`,
      UpdatedProfileObj
    );
    return response;
  } catch (error) {
    // Handle any errors here
    throw error;
  }
};
export const UploadUserProfileImage = async (agencyId, formdata) => {
  try {
    const response = await AgencyLoginHeader.post(
      `${base_url}Agency/UploadUserProfileImage?loginId=${agencyId}`,
      formdata
    );
    return response;
  } catch (error) {
    // Handle any errors here
    throw error;
  }
};

export const GetUsers = async agencyId => {
  try {
    const response = await AgencyLoginHeader.get(
      `${base_url}Agency/GetUsers?agencyId=${agencyId}`
    );
    return response;
  } catch (error) {
    // Handle any errors here
    throw error;
  }
};

export const GetUserRoles = async agencyId => {
  try {
    const response = await AgencyLoginHeader.get(
      `${base_url}Agency/GetUserRoles?agencyId=${agencyId}`
    );
    return response;
  } catch (error) {
    // Handle any errors here
    throw error;
  }
};

export const CreateAgencyUser = async userObj => {
  try {
    const response = await AgencyLoginHeader.post(
      `${base_url}Agency/SignUp`,
      userObj
    );
    return response;
  } catch (error) {
    // Handle any errors here
    throw error;
  }
};

// key optinal
// current password necessary
// Without Logged In
export const UpdateAgencyPassword = async (
  newPass,
  currentPass,
  UserLoggedInEmail
) => {
  try {
    const response = await AgencyLoginHeader.post(
      `${base_url}Agency/UpadatePassword`,
      {
        loginId: UserLoggedInEmail,
        passwordKey: '',
        password: newPass,
        currentPassword: currentPass
      }
    );
    return response;
  } catch (error) {
    // Handle any errors here
    throw error;
  }
};

// logged In User Pass Change
export const UpadateForgotPassword = async (
  confirmPassword,
  ForgotPassKey,
  LoginEmail
) => {
  try {
    const response = await AgencyLoginHeader.post(
      `${base_url}Agency/UpadateForgotPassword`,
      {
        loginId: LoginEmail,
        passwordKey: ForgotPassKey,
        password: confirmPassword,
        currentPassword: ''
      }
    );
    return response;
  } catch (error) {
    // Handle any errors here
    throw error;
  }
};

//Shipments Details Api's

export const GetShipments = async (
  agencyId,
  loggedInEmail,
  pageNum,
  pageSize,
  searchQuery,
  shipmentStatuses
) => {
  try {
    const response = await AgencyLoginHeader.get(
      `${base_url}Shipment/GetShipments?agencyId=${agencyId}&loginId=${loggedInEmail}&pageNumber=${pageNum}&pageSize=${pageSize}&searchQuery=${searchQuery}&shipmentStatuses=${shipmentStatuses}`
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const GetShipmentDetails = async shipmentId => {
  try {
    const response = await AgencyLoginHeader.get(
      `${base_url}Shipment/GetShipmentDetails?shipmentId=${shipmentId}`
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const GetLogoBase64 = async filePath => {
  try {
    const response = await AgencyLoginHeader.get(
      `${base_url}Agency/GetLogoBase64?filePath=${filePath}`
    );
    return response;
  } catch (error) {
    throw error;
  }
};
// export const GetShipmentsDemo = async page => {
//   try {
//     const response = await AgencyLoginHeader.get(
//       `https://api.cmstravel.pk/app/tms/shippments/?page=${page}`
//     );
//     return response;
//   } catch (error) {
//     throw error;
//   }
// };

export const ImportOrder = async (agencyId, formdata) => {
  try {
    const response = await AgencyLoginHeader.post(
      `${base_url}Shipment/ImportOrder?agencyId=${agencyId}`,
      formdata
    );
    return response;
  } catch (error) {
    // Handle any errors here
    throw error;
  }
};

export const GetLocations = async agencyId => {
  // return await axios.get(`${base_url}Agency/GetLocations?agencyId=${agencyId}`);
  try {
    const response = await AgencyLoginHeader.get(
      `${base_url}Agency/GetLocations?agencyId=${agencyId}`
    );
    return response;
  } catch (error) {
    // Handle any errors here
    throw error;
  }
};

export const UpdateCarrierRateQuoteSettings = async obj => {
  try {
    const response = await AgencyLoginHeader.post(
      `${base_url}Agency/UpdateCarrierRateQuoteSettings`,
      obj
    );
    return response;
  } catch (error) {
    // Handle any errors here
    throw error;
  }
};

export const AgencyAdminGetProfile = async AgencyLoginID => {
  try {
    const response = await AgencyLoginHeader.get(
      `${base_url}Agency/GetProfile?loginId=${AgencyLoginID}`
    );
    return response;
  } catch (error) {
    // Handle any errors here
    throw error;
  }
};

// Carriers Rate Api's

// export const AllApisCall = async (finalCarrierApis, obj) => {
//   const requests = finalCarrierApis.map(url => axios.post(url, obj));
//   return await Promise.all(requests);
// };

// shipments results

export const GetBillOfLading = async obj => {
  // return await axios.post(`${base_url}Carriers/BillOfLading`, obj);
  try {
    const response = await AgencyLoginHeader.post(
      `${base_url}Carriers/BillOfLading`,
      obj
    );
    return response;
  } catch (error) {
    // Handle any errors here
    throw error;
  }
};

export const GetProofOfDelivery = async obj => {
  // return await axios.post(`${base_url}Carriers/ProofOfDelivery`, obj);
  try {
    const response = await AgencyLoginHeader.post(
      `${base_url}Carriers/ProofOfDelivery`,
      obj
    );
    return response;
  } catch (error) {
    // Handle any errors here
    throw error;
  }
};

export const TrackShipmentRequest = async obj => {
  // return await axios.post(`${base_url}Carriers/TrackShipment`, obj);
  try {
    const response = await AgencyLoginHeader.post(
      `${base_url}Carriers/TrackShipment`,
      obj
    );
    return response;
  } catch (error) {
    // Handle any errors here
    throw error;
  }
};

// carrier numbers to call for api

export const GetCarriers = async (agencyId, locationId) => {
  return await AgencyLoginHeader.get(
    `${base_url}Agency/GetAllCarriers?agencyId=${agencyId}&locationId=${
      locationId ? locationId : 0
    }`
  );
};

export const GetActiveCarriers = async (agencyId, locationId) => {
  return await AgencyLoginHeader.get(
    `${base_url}Agency/GetActiveCarriers?agencyId=${agencyId}&locationId=${
      locationId ? locationId : 0
    }`
  );
};

//pickup shipment
export const PickupShipment = async obj => {
  return await AgencyLoginHeader.post(
    `${base_url}Carriers/PickupShipment`,
    obj
  );
};

export const CreateShipment = async obj => {
  return await AgencyLoginHeader.post(
    `${base_url}Shipment/CreateShipment`,
    obj
  );
};

export const DispatchShipment = async obj => {
  return await AgencyLoginHeader.post(
    `${base_url}Shipment/DispatchShipment`,
    obj
  );
};

export const HoldShipment = async obj => {
  return await AgencyLoginHeader.post(`${base_url}Shipment/HoldShipment`, obj);
};

export const UnbookShipment = async obj => {
  return await AgencyLoginHeader.post(
    `${base_url}Shipment/UnbookShipment`,
    obj
  );
};

export const CancelShipment = async obj => {
  return await AgencyLoginHeader.post(
    `${base_url}Shipment/CancelShipment`,
    obj
  );
};

export const UnHoldShipment = async obj => {
  return await AgencyLoginHeader.post(
    `${base_url}Shipment/UnHoldShipment`,
    obj
  );
};

export const GetCarrierDetails = async (agencyId, carrierId, locationID) => {
  return await AgencyLoginHeader.get(
    `${base_url}Agency/GetCarrierDetails?agencyId=${agencyId}&carrierId=${carrierId}&locationId=${locationID}`
  );
};

export const UpdateCarrierDetails = async carriersArray => {
  return await AgencyLoginHeader.post(
    `${base_url}Agency/UpdateCarrierDetails`,
    carriersArray
  );
};

export const UpdateCarrierBillTo = async data => {
  return await AgencyLoginHeader.post(
    `${base_url}Agency/UpdateCarrierBillTo`,
    data
  );
};

export const GetTrackShipment = async reffNO => {
  return await AgencyLoginHeader.get(
    `${base_url}Shipment/GetShipment?orderNumber=${reffNO}`
  );
};

export const GetCarrierEProSeries = async carrierId => {
  return await AgencyLoginHeader.get(
    `${base_url}Agency/CarrierEProSeries?carrierId=${carrierId}`
  );
};

export const GetLocationEProSeries = async (locationId, carrierId) => {
  return await AgencyLoginHeader.get(
    `${base_url}Agency/CarrierEProSeries?carrierId=${carrierId}&locationId=${locationId}`
  );
};

export const AddUpdateLocation = async locationObj => {
  return await AgencyLoginHeader.post(
    `${base_url}Agency/AddUpdateLocation`,
    locationObj
  );
};

export const AddUpdateLocationCarrier = async locationCarrierObj => {
  return await AgencyLoginHeader.post(
    `${base_url}Agency/AddUpdateLocationCarrier`,
    locationCarrierObj
  );
};

export const GetAgencyLocations = async (agencyID, locationId) => {
  return await AgencyLoginHeader.get(
    `${base_url}Agency/GetAgencyLocations?agencyId=${agencyID}&locationId=${locationId}`
  );
};

export const SaveAppRegistration = async appRegistrationObj => {
  return await AgencyLoginHeader.post(
    `${base_url}Agency/SaveAppRegistration`,
    appRegistrationObj
  );
};

export const GetAppRegistrations = async agencyID => {
  return await AgencyLoginHeader.get(
    `${base_url}Agency/GetAppRegistrations?agencyId=${agencyID}`
  );
};

export const UpdateCarrierSurcharge = async obj => {
  return await AgencyLoginHeader.post(
    `${base_url}Agency/UpdateCarrierSurcharge`,
    obj
  );
};

export const GetNextProNumber = async (agencyID, carrierId) => {
  return await AgencyLoginHeader.get(
    `${base_url}Carriers/GetNextProNumber?agencyId=${agencyID}&carrierId=${carrierId}`
  );
};

export const GetAllCarriers = async type => {
  return await AgencyLoginHeader.get(
    `${base_url}Admin/GetCarriers?carrierType=${type}`
  );
};

export const GetImportFieldNames = async UpdatedProfileObj => {
  return await AgencyLoginHeader.get(
    `${base_url}Shipment/GetImportFieldNames`,
    {
      UpdatedProfileObj
    }
  );
};

export const ImportCSVMapping = async (
  CsvArray,
  AgencyID,
  UserLoggedInEmail
) => {
  return await AgencyLoginHeader.post(
    `${base_url}Shipment/ImportCSVMapping?agencyId=${AgencyID}&loginId=${UserLoggedInEmail}`,
    CsvArray
  );
};

export const UpdateCarrierProSeries = async obj => {
  return await AgencyLoginHeader.post(
    `${base_url}Agency/UpdateCarrierProSeries`,
    obj
  );
};

export const UpdateCarrierThirdPartyInfo = async obj => {
  return await AgencyLoginHeader.post(
    `${base_url}Agency/UpdateCarrierThirdPartyInfo`,
    obj
  );
};

export const UpdateLocationCarrier = async obj => {
  return await AgencyLoginHeader.post(
    `${base_url}Agency/UpdateLocationCarrier`,
    obj
  );
};

export const CreateOrUpdateCarrier = async obj => {
  return await AgencyLoginHeader.post(
    `${base_url}Agency/CreateOrUpdateCarrier`,
    obj
  );
};

export const StartTLQuote = async obj => {
  return await AgencyLoginHeader.post(`${base_url}Shipment/StartTLQuote`, obj);
};

export const GetQuote = async quoteId => {
  return await AgencyLoginHeader.get(
    `${base_url}Shipment/GetQuote?quoteId=${quoteId}`
  );
};

export const GetQuoteDetail = async sessionId => {
  return await axios.get(
    `${base_url}Shipment/GetQuoteDetail?sessionId=${sessionId}`
  );
};

export const LogTLCarrierResponse = async chatObj => {
  return await axios.post(`${base_url}Shipment/LogTLCarrierResponse`, chatObj);
};

export const AddMoreRateRequest = async sessionId => {
  return await axios.post(
    `${base_url}Shipment/AddMoreRate?sessionId=${sessionId}`
  );
};

export const LogTLShipperResponse = async chatObj => {
  return await AgencyLoginHeader.post(
    `${base_url}Shipment/LogTLShipperResponse`,
    chatObj
  );
};

export const BookTLShipment = async bookTLShipmentObj => {
  return await AgencyLoginHeader.post(
    `${base_url}Shipment/BookTLShipment`,
    bookTLShipmentObj
  );
};

export const ResendQuote = async sessionId => {
  try {
    const response = await AgencyLoginHeader.get(
      `${base_url}Shipment/ResendQuote?sessionId=${sessionId}`,
      sessionId
    );
    return response;
  } catch (error) {
    // Handle any errors here
    throw error;
  }
};

export const GetShipmentActivity = async shipmentId => {
  try {
    const response = await AgencyLoginHeader.get(
      `${base_url}Shipment/GetShipmentActivity?shipmentId=${shipmentId}`
    );
    return response;
  } catch (error) {
    // Handle any errors here
    throw error;
  }
};

export const CreateAgencyRole = async obj => {
  return await AgencyLoginHeader.post(
    `${base_url}Agency/CreateAgencyRole`,
    obj
  );
};

export const GetRolePermissions = async roleId => {
  return await AgencyLoginHeader.get(
    `${base_url}Agency/GetRolePermissions?roleId=${roleId}`
  );
};

export const ResendPassword = async loginId => {
  return await AgencyLoginHeader.get(
    `${base_url}Agency/ResendPassword?loginId=${loginId}`
  );
};

export const GetCarrierLocations = async (agencyId, carrierId) => {
  return await AgencyLoginHeader.get(
    `${base_url}Agency/GetCarrierLocations?agencyId=${agencyId}&carrierId=${carrierId}`
  );
};

export const UploadCarrierLogo = async (agencyId, formData) => {
  return await AgencyLoginHeader.post(
    `${base_url}Agency/UploadCarrierLogo?agencyId=${agencyId}`,
    formData
  );
};

export const UpdateShipmentLocation = async obj => {
  return await AgencyLoginHeader.post(
    `${base_url}Shipment/UpdateShipmentLocation`,
    obj
  );
};

export const CheckLocation = async shipmentId => {
  return await AgencyLoginHeader.get(
    `${base_url}Shipment/CheckLocation?shipmentId=${shipmentId}`
  );
};

export const GetNextDate = async (startDate, days, dateFormat) => {
  return await AgencyLoginHeader.get(
    `${base_url}Shipment/GetNextDate?startDate=${startDate}&days=${days}&outputFormat=${dateFormat}`
  );
};

export const SaveUserSettings = async obj => {
  return await AgencyLoginHeader.post(
    `${base_url}Agency/SaveUserSettings`,
    obj
  );
};

export const GetLocationsJSON = async squery => {
  return await axios.get(
    // `${base_url}Carriers/SearchLocation?query=${squery} `
    `https://api.tms.shiptechpro.com/Carriers/SearchLocation?query=${squery}`
  );
};

export const EmbedDashboard = async () => {
  return await AgencyLoginHeader.get(`${base_url}Dashboard/EmbedDashboard`);
};

import * as React from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import './TrackShipment.css';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import { TimelineOppositeContent } from '@mui/lab';
import { Box, Typography } from '@mui/material';

function TrackHistory(props) {
  const trackShipmentData = props?.trackShipmentData;
  const [state, setState] = React.useState(true);
  const toggleDrawer = (anchor, open) => event => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  return (
    <div>
      {['2'].map(anchor => (
        <React.Fragment key={anchor}>
          <Box
          // sx={{ display: 'flex', justifyContent: 'flex-end', width: '40vw' }}
          >
            <Button
              size='small'
              variant='outlined'
              sx={{ width: '150px' }}
              onClick={toggleDrawer(anchor, true)}
            >
              View History
            </Button>
          </Box>
          <Drawer
            anchor='right'
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            <Timeline
              position='right'
              sx={{
                marginTop: 5,
                marginBottom: 1,
                '& .MuiTimelineOppositeContent-root': {
                  flex: 0.3,
                  textAlign: 'center',
                  paddingRight: '16px'
                },
                '& .MuiTimelineContent-root': {
                  flex: 0.7
                }
              }}
            >
              {trackShipmentData?.tracingLines?.map((item, index) => (
                <TimelineItem key={index}>
                  <TimelineOppositeContent
                    sx={{ minWidth: 220, textAlign: 'right' }}
                  >
                    <Typography
                      variant='body2'
                      color='text.secondary'
                      sx={{
                        whiteSpace: 'nowrap',
                        fontWeight: 'bold',
                        textAlign: 'right'
                      }}
                    >
                      {item.startDateTime}
                    </Typography>
                  </TimelineOppositeContent>

                  <TimelineSeparator>
                    <TimelineDot
                      sx={{
                        width: 19,
                        height: 19,
                        // mr: 1,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}
                      color={
                        trackShipmentData?.shipmentStatus === 3
                          ? 'warning'
                          : 'success'
                      }
                    >
                      <CheckCircleIcon sx={{ fontSize: '17' }} />
                    </TimelineDot>
                    {index < trackShipmentData.tracingLines.length - 1 && (
                      <TimelineConnector />
                    )}
                  </TimelineSeparator>

                  <TimelineContent>
                    <Typography variant='body1' sx={{ fontSize: 15 }}>
                      {item.description} {item?.location}
                    </Typography>
                  </TimelineContent>
                </TimelineItem>
              ))}
            </Timeline>
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}

export default TrackHistory;
